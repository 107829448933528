import request from '@/utils/request'

const shopApi = {
    base: '/api/v1/rest/shops',
    query: '/api/v1/shop/query',
    delete: '/api/v1/shop/delete',
    edit: '/api/v1/shop/edit',
    isExist: '/api/v1/shop/isExist'
}

export function shops(param) {
    const url = request.requestFormat(shopApi.base, param)
    return request({
        url,
        method: 'get'
    })
}

export function shopQuery(param) {
    const url = request.requestFormat(shopApi.query, param)
    return request({
        url,
        method: 'get'
    })
}

export function shopEdit(data) {
    return request({
        url: shopApi.edit,
        method: 'post',
        data
    })
}

export function shopGet(id) {
    return request({
        url: shopApi.base + `/${id}`,
        method: 'get'
    })
}

export function shopPatch(id, data) {
    return request({
        url: shopApi.base + `/${id}`,
        method: 'patch',
        data
    })
}

export function shopPut(id, data) {
    return request({
        url: shopApi.base + `/${id}`,
        method: 'put',
        data
    })
}

export function shopDelete(param) {
    const url = request.requestFormat(shopApi.delete, param)
    return request({
        url,
        method: 'get'
    })
}

export function shopIsExist(param) {
    const url = request.requestFormat(shopApi.isExist, param)
    return request({
        url,
        method: 'get'
    })
}
