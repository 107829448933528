<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-tabs type="card" @change="tabChange">
        <template v-for="tab in tabs">
          <a-tab-pane :activeKey="currentTab" :tab="tab.name" :key="tab.id"></a-tab-pane>
        </template>
      </a-tabs>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="根据备注查询">
                <a-input v-model="queryParam.remark" allow-clear placeholder="请输入备注" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="addRow">新建</a-button>
        <a-button type="primary" icon="download" @click="excelExport">导出</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        showPagination="auto"
        :totalCount="totalCount"
        :alert="true"
      >
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
      </s-table>

      <invitation-create ref="invitationCreate" @reload="reload" @tabChange="tabChange" />
      <invitation-delete ref="invitationDelete" :selectedRows="selectedRows" @reload="deleteReload" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryCode } from '@/api/broker'
import InvitationCreate from './Invitation/invitationCreate'
import InvitationDelete from './Invitation/invitationDelete'
import { tabs, statusMap, usedColumns, unusedColumns, expiredColumns } from './js/invitation'

export default {
  name: 'Invitation',
  components: {
    STable,
    Ellipsis,
    InvitationCreate,
    InvitationDelete
  },
  data() {
    return {
      tabs,
      currentTab: 'unused',
      columns: unusedColumns,
      usedColumns,
      unusedColumns,
      expiredColumns,
      totalCount: 0,
      noticeTitle: '邀请码管理',

      queryParam: {},
      params: {},

      selectedRowKeys: [],
      selectedRows: [],

      loadData: parameter => {
        const param = this.getParam()
        const params = this.paramFormat(parameter, param)
        this.params = params
        Object.assign(params, this.queryParam);
        return queryCode(params).then(res => {
          const result = this.resFormat(res, 'area')
          this.totalCount = result.totalCount
          return result
        })
      }
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    serial() {
      return this.$store.state.env.serial
    },
    host() {
      return this.$store.state.oss.host
    },
    user() {
       return this.$store.state.user
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    deleteReload() {
      this.selectedRows = []
      this.reload()
    },
    addRow() {
      this.$refs.invitationCreate.showModal()
    },
    excelExport() {
      const href = window.origin + '/api/v1/invite-code/export'
      window.location.href = this.requestFormat(href, this.params)
    },
    deleteRow() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.$refs.invitationDelete.showModal()
      }
    },
    getParam() {
      switch (this.currentTab) {
        case 'unused':
          return {
            expired: false,
            used: false
          }
        case 'used':
          return {
            used: true
          }
        case 'expired':
          return {
            used: false,
            expired: true
          }
      }
    },
    tabChange(value) {
      this.currentTab = value
      this.queryParam = {}
      switch (value) {
        case 'used':
          this.columns = usedColumns
          break;
        case 'unused':
          this.columns = unusedColumns
          break;
        case 'expired':
          this.columns = expiredColumns
          break;
      }
      this.reload()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
