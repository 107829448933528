<template>
  <div>
    <a-modal
      title="新建邀请码"
      :width="modalWidth"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-spin :spinning="loading">
        <template slot="footer">
          <a-button key="back" @click="handleCancel">
            取消
          </a-button>
          <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
            确定
          </a-button>
        </template>
        <a-form :form="form">
          <a-form-item
            label="备注"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'remark',
                {
                  rules: [
                    { message: '请输入备注' },
                    { max: 50, message: '长度不能大于50' }
                  ]
                }
              ]"
              name="remark"
              placeholder="请输入备注"
            />
          </a-form-item>
          <a-form-item
            label="生成数量"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input-number 
              :min="0"
              v-decorator="[
                'count',
                {
                  rules: [
                    { required: true, message: '请输入数量' }
                  ]
                }
              ]"
              name="count"
              placeholder="请输入数量"
            />
          </a-form-item>
          <a-form-item
            label="有效期"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-date-picker
              class="inputLong"
              v-decorator="['deadline', { rules: [{ required: true, message: '请选择有效期' }] }]"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="请选择有效期"
            />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { createCode } from '@/api/broker'
import { getDate } from '@/utils/util'
export default {
  name: 'BaseForm',
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      visible: false
    }
  },
  methods: {
    showModal() {
      this.visible = true
      this.loading = false
      this.form.resetFields()
    },
    handleCancel() {
      this.visible = false
    },
    handleOk(e) {
      this.loading = true
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          const time = getDate(values.deadline._d)
          const requestParameters = {
            expireDate: time,
            number: values.count,
            remark: values.remark
          }
          createCode(requestParameters)
            .then(res => {
              const description = `${values.remark}成功生成了${values.count}个有效期至${getDate(values.deadline._d)}的邀请码`
              this.notiSuccess('生成邀请码', description)
              this.visible = false
              this.$emit('reload')
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  /deep/ .ant-form-item {
    margin-bottom: 35px;
  }
}
</style>
