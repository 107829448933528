var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"title":"新建邀请码","width":_vm.modalWidth,"visible":_vm.visible,"confirmLoading":_vm.loading,"maskClosable":false},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")]),_c('a-button',{key:"submit",attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v(" 确定 ")])],1),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"备注","labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              {
                rules: [
                  { message: '请输入备注' },
                  { max: 50, message: '长度不能大于50' }
                ]
              }
            ]),expression:"[\n              'remark',\n              {\n                rules: [\n                  { message: '请输入备注' },\n                  { max: 50, message: '长度不能大于50' }\n                ]\n              }\n            ]"}],attrs:{"name":"remark","placeholder":"请输入备注"}})],1),_c('a-form-item',{attrs:{"label":"生成数量","labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'count',
              {
                rules: [
                  { required: true, message: '请输入数量' }
                ]
              }
            ]),expression:"[\n              'count',\n              {\n                rules: [\n                  { required: true, message: '请输入数量' }\n                ]\n              }\n            ]"}],attrs:{"min":0,"name":"count","placeholder":"请输入数量"}})],1),_c('a-form-item',{attrs:{"label":"有效期","labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['deadline', { rules: [{ required: true, message: '请选择有效期' }] }]),expression:"['deadline', { rules: [{ required: true, message: '请选择有效期' }] }]"}],staticClass:"inputLong",attrs:{"show-time":"","format":"YYYY-MM-DD HH:mm:ss","placeholder":"请选择有效期"}})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }