import request from '@/utils/request'

const brokersApi = {
  // get brokers info
  brokers: '/api/v1/rest/brokers',
  create: '/api/v1/invite-code/create',
  findByEnterStatusAndKeyword: '/api/v1/rest/brokers/search/findByEnterStatusAndKeyword',
  findByEnterStatus: '/api/v1/rest/brokers/search/findByEnterStatus',
  query: '/api/v1/broker/query',
  findBank: '/api/v1/broker/findBank',
  
  // code
  inviteCodes: '/api/v1/rest/inviteCodes',
  findUsed: '/api/v1/rest/inviteCodes/search/findUsed',
  findUnUsed: '/api/v1/rest/inviteCodes/search/findUnUsed',
  queryCode: '/api/v1/invite-code/query',
  exportCode: '/api/v1/invite-code/export',
  createCode: '/api/v1/invite-code/create'
}

export function brokers(param) {
  let url = brokersApi.brokers
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function addBrokers(param) {
  return request({
    url: brokersApi.brokers,
    method: 'post',
    data: param
  })
}

export function editBrokers(param) {
  return request({
    url: brokersApi.brokers + `/${param.id}`,
    method: 'patch',
    data: param
  })
}

export function delBrokers(param) {
  return request({
    url: brokersApi.brokers + `/${param.id}`,
    method: 'delete'
  })
}

export function brokerGet(id) {
  return request({
    url: brokersApi.brokers + `/${id}`,
    method: 'get'
  })
}

export function inviteCodes(param) {
  let url = brokersApi.inviteCodes
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function addInviteCodes(param) {
  let url = brokersApi.create
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'post'
  })
}

export function editInviteCodes(param) {
  return request({
    url: brokersApi.inviteCodes + `/${param.id}`,
    method: 'patch',
    data: param
  })
}

export function findByIdInviteCodes(param) {
  return request({
    url: brokersApi.inviteCodes + `/${param.id}`,
    method: 'get'
  })
}

export function findBrokers(param) {
  let url = brokersApi.findByEnterStatusAndKeyword
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function queryBrokers(param) {
  let url = brokersApi.query
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findEnterStatus(param) {
  let url = brokersApi.findByEnterStatus
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function delInviteCodes(param) {
  return request({
    url: brokersApi.inviteCodes + `/${param.id}`,
    method: 'delete'
  })
}

export function findUsed(params) {
  let url = brokersApi.findUsed
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}

export function findUnUsed(params) {
  let url = brokersApi.findUnUsed
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}

export function createCode(params) {
  let url = brokersApi.createCode
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url: url,
    method: 'post'
  })
}

export function queryCode(params) {
  let url = brokersApi.queryCode
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}

export function exportCode(params) {
  let url = brokersApi.exportCode
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}

export function getBankInfo(params) {
  let url = brokersApi.findBank
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}
