
export const expiredColumns = [
    {
      title: '邀请码',
      dataIndex: 'code'
    },
    {
      title: '过期日期',
      dataIndex: 'expireDate'
    },
    {
      title: '备注',
      dataIndex: 'remark'
    },
    {
      title: '生成时间',
      dataIndex: 'createTime',
      sorter: true
    }
  ]
  
export const usedColumns = [
    {
      title: '邀请码',
      dataIndex: 'code'
    },
    {
      title: '生成时间',
      dataIndex: 'createTime',
      sorter: true
    },
    {
      title: '绑定经纪人',
      dataIndex: 'name'
    },
    {
      title: '手机号',
      dataIndex: 'phone'
    },
    {
      title: '备注',
      dataIndex: 'remark'
    },
    {
      title: '使用时间',
      dataIndex: 'usedTime',
      sorter: true
    }
  ]
  
  export const unusedColumns = [
    {
      title: '邀请码',
      dataIndex: 'code'
    },
    {
      title: '过期日期',
      dataIndex: 'expireDate'
    },
    {
      title: '状态',
      dataIndex: 'enabled',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: '备注',
      dataIndex: 'remark'
    },
    {
      title: '生成时间',
      dataIndex: 'createTime',
      sorter: true
    }
  ]
  
  export const statusMap = {
    false: {
      status: 'error',
      text: '无效'
    },
    true: {
      status: 'success',
      text: '有效'
    }
  }
  
  export const tabs = [
    {
      name: '未使用',
      id: 'unused'
    },
    {
      name: '已使用',
      id: 'used'
    },
    {
      name: '已过期',
      id: 'expired'
    }
  ]
